import { useState } from 'react';
import "./style.css";
import emailjs from '@emailjs/browser';
import i18next from 'i18next';

function FaqContact() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [company, setCompanny] = useState('');
  const [phone, setPhone] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  function sendEmail(e) {
    e.preventDefault();

    const newErrors = {};
    if (name === '') newErrors.name = true;
    if (email === '') newErrors.email = true;
    if (message === '') newErrors.message = true;
    if (company === '') newErrors.company = true;
    if (phone === '') newErrors.phone = true;

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setShowErrorMessage(true);
      return;
    }

    setLoading(true);
    setShowErrorMessage(false);

    const templateParams = {
      message: message,
      from_name: name,
      phone: phone,
      company: company,
      email: email,
    };

    emailjs.send("service_8bkbs3j", "template_cczvs09", templateParams, "1EqbA9bCbSSaoOolW")
      .then((res) => {
        console.log('email sended', res.status, res.text);
        setName('');
        setEmail('');
        setMessage('');
        setCompanny('');
        setPhone('');
        setErrors({});
      }, (err) => {
        console.log("error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <div id="faqContactMain">
        {loading ? (
          <div className="spinner"></div>
        ) : (
          <>
            <div id="faqText">
              <p>{i18next.t('faq.faqFormLabels.contactText')}</p>
            </div>
            <form id="faqform" onSubmit={sendEmail}>
              <input 
                className={`input ${errors.name ? 'error' : ''}`}
                type="text"
                placeholder={i18next.t('faq.faqFormLabels.namePlaceholder')}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />

              <input 
                className={`input ${errors.company ? 'error' : ''}`}
                type="text"
                placeholder={i18next.t('faq.faqFormLabels.companyPlaceholder')}
                onChange={(e) => setCompanny(e.target.value)}
                value={company}
              />
                        
              <input 
                className={`input ${errors.email ? 'error' : ''}`}
                type="text"
                placeholder={i18next.t('faq.faqFormLabels.emailPlaceholder')}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />

              <input 
                className={`input ${errors.phone ? 'error' : ''}`}
                placeholder={i18next.t('faq.faqFormLabels.phonePlaceholder')}
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
              />

              <textarea 
                className={`input textarea ${errors.message ? 'error' : ''}`}
                placeholder={i18next.t('faq.faqFormLabels.messagePlaceholder')}
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
              
              {showErrorMessage && (
                <p className="error-message">{i18next.t('faq.faqFormLabels.errorSubmit')}</p>
              )}
              <input className="button" type="submit" value={i18next.t('faq.faqFormLabels.submitButtonLabel')} />
            </form>
          </>
        )}
      </div>
    </>
  );
}

export default FaqContact;
