import './style.css'
import React, { useState, useEffect } from 'react';
import { i18n } from "../../../translate/i18n"

function TextHome(){
  
  //FUNCTIONS
    const screenSizeUpdate = () => {
      const width = window.innerWidth
      setIsSpecificSize(((width >= 720) && (width <= 1023)))
    }
  //FUNCTIONS

  //VARIABLES
    const [isSpecificSize, setIsSpecificSize ] = useState(false)
  //VARIABLES
  
  //HOOKS
    useEffect(() => {
      window.addEventListener('resize', screenSizeUpdate)

      return () => window.removeEventListener('resize', screenSizeUpdate)

    }, [])
  //HOOKS

  return(
    <>
      <div id='boxTextHome'>
        {
          !isSpecificSize? 
          (
            <>
              <h1 id='h1Home' className='text'>
              <span className='blue'>{i18n.t('home.text.h1.line1.1')}</span><br/>
              <span className='blue'>{i18n.t('home.text.h1.line2.1')}</span> {i18n.t('home.text.h1.line2.2')} <br/>
              <span className='blue'>{i18n.t('home.text.h1.line3.1')}</span><br/>
              <span className='blue'>{i18n.t('home.text.h1.line4.1')}</span> {i18n.t('home.text.h1.line4.2')} <span className='blue'>{i18n.t('home.text.h1.line4.3')}</span>
              </h1>
              <p id='pHome' className='text'>
                <span className='blue'>{i18n.t('home.text.p1.line1.1')}</span> {i18n.t('home.text.p1.line1.2')} <span className='blue'>{i18n.t('home.text.p1.line1.3')} </span><br/>
                <span className='blue'>{i18n.t('home.text.p1.line2.1')}</span><span className='blue'> {i18n.t('home.text.p1.line2.2')}</span> {i18n.t('home.text.p1.line2.3')}<br/>
                {i18n.t('home.text.p1.line3.1')}<br/>
              </p>
            </>
          ) 
          : 
          (
            <>
              <h1 id='h1Home' className='text'>
                <span className='blue'>{i18n.t('home.text.h1.line1.1')} </span><br/>
                <span className='blue'>{i18n.t('home.text.h1.line2.1')}</span> {i18n.t('home.text.h1.line2.2')} 
                <span className='blue'> {i18n.t('home.text.h1.line3.1')} </span>
                <span className='blue'>{i18n.t('home.text.h1.line4.1')}</span> {i18n.t('home.text.h1.line4.2')} <span className='blue'>{i18n.t('home.text.h1.line4.3')}</span>
              </h1>
              <p id='pHome' className='text'>
                <span className='blue'>{i18n.t('home.text.p2.line1.1')}</span> {i18n.t('home.text.p2.line1.2')}<br/>
                {i18n.t('home.text.p2.line2.1')} <span className='blue'>{i18n.t('home.text.p2.line2.2')}<br/></span>
                <span className='blue'> {i18n.t('home.text.p2.line3.1')}</span> {i18n.t('home.text.p2.line3.2')} <br/>
                {i18n.t('home.text.p2.line4.1')}
              </p>     
            </>
          )
        }
     </div>
    </>
  )
}

export default TextHome
