import FaqContact from './contact';
import FaqTextLeft from './text-left';
import "./style.css"

function Faq(){
  return(
    <>
      <div id="faqBox">
        <div id="faqMain">
          <FaqTextLeft/>
          <div id="divider"/>
          <FaqContact/>
        </div>
      </div>
    </>
  )
}

export default Faq