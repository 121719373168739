import { messages as portuguesMessages } from './pt'
import { messages as englishMessages } from './en'
import { messages as spanishMessages } from './es'


const messages = {
  ...portuguesMessages,
  ...englishMessages,
  ...spanishMessages
}

export {messages}