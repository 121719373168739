const messages = {
  es: {
    translations: {
      header: {
        menus: {
          home: 'Inicio',
          services: 'Servicios',
          coverage: 'Cobertura',
          about: 'Acerca',
          faq: 'faq',
          contact: 'Contacto'
        },
        logo: {
          description_image: 'Esta es la bandera de España'
        },
        description_es_image: 'Esta es la bandera de España',
        description_br_image: 'Esta é a bandeira do Brasil',
        description_us_image: 'Esta es la bandera de los Estados Unidos',
      },
      home: {
        text: {
          h1: {
            line1: {
              1: 'Neutra Telecom'
            },
            line2: {
              1: 'Innovación',
              2: 'que impulsa y'
            },
            line3: {
              1: 'conecta.'
            },
            line4: {
              1: 'Tecnología',
              2: 'que',
              3: 'acerca.'
            }
          },
          p1: {
            line1: {
              1: 'Conectividad global',
              2: 'para su negocio descubre nuestros servicios',
              3: 'líderes en'
            },
            line2: {
              1: 'tráfico IP',
              2: 'internacional',
              3: 'y más. Alcance'
            },
            line3: {
              1: 'nuevos horizontes ahora!'
            }
          },
          p2: {
            line1: {
              1: 'Conectividad global',
              2: 'para su negocio '
            },
            line2: {
              1: 'descubre nuestros servicios',
              2: 'líderes en tráfico IP',
            },
            line3: {
              1: 'internacional',
              2: 'y más.'
            },
            line4: {
              1: 'Alcance nuevos horizontes ahora!'
            }
          },
        },
        button: {
          textButton: 'Contactar'
        }
      },
      services: {
        " line1" : {
          " 1" : " Conexiones" ,
          " 2" : " B2B" ,
          " 3" : " Personalizadas" 
        },
        " sdWan" : {
          " line1" : " SD-WAN",
          "line2": {
            "1": "1. Agilidad y Flexibilidad:",
            "2": "Simplifica la administración de tu red y obtén agilidad para adaptarte rápidamente a los cambios en las necesidades de conectividad de tu empresa."
          },
          "line3": {
            "1": "2. Rendimiento Optimizado:",
            "2": "Asegura el rendimiento óptimo para aplicaciones críticas, dirigiendo el tráfico inteligentemente y dinámicamente según las condiciones de la red en tiempo real."
          },
          "line4": {
            "1": "3. Ahorro de Costos:",
            "2": "Reducir costos de conectividad utilizando una variedad de enlaces de red, como internet, MPLS, 4G y 5G. Seguridad Avanzada: Protege tu red contra amenazas cibernéticas con características de seguridad avanzadas integradas, manteniendo tus datos y información seguros en todo momento."
          }
        },
        "transitoIp": {
          "line1": "Tránsito IP",
          "line2": {
            "1": "1. Rendimiento Excepcional:",
            "2": "Maximiza el rendimiento de tu red con rutas redundantes y baja latencia, asegurando una experiencia de conexión superior para tus usuarios en cualquier parte del mundo."
          },
          "line3": {
            "1": "2. Escalabilidad Flexible:",
            "2": "Escala tu infraestructura de acuerdo a las demandas de tu negocio, asegurando que tu red siempre esté lista para crecer a medida que evolucionen tus necesidades."
          },
          "line4": {
            "1": "3. Soporte Especializado:",
            "2": "Confía en nuestro equipo de expertos para proporcionar soporte global personalizado y asegurar que tu infraestructura funcione de manera eficiente y confiable."
          }
        },
        "datacenter": {
          "line1": "Centro de Datos",
          "line2": {
            "1": "1. Conexión Global:",
            "2": "Utiliza nuestra red para conectar tus negocios y necesidades en diferentes partes del mundo, asegurando una transferencia rápida y segura de datos entre tus operaciones globales."
          },
          "line3": {
            "1": "2. Acceso a Centros de Datos Principales:",
            "2": "Tienes acceso directo a los principales centros de datos del mundo con nuestra red, permitiéndote llegar a nuevos mercados y aprovechar infraestructuras de computación en la nube de primer nivel y servicios de alojamiento."
          },
          "line4": {
            "1": "3. Conexión a PTT:",
            "2": "A través de nuestra red, ofrecemos conexión directa a las principales PTT (Puntos de Intercambio de Tráfico), asegurando una ruta de datos eficiente y una experiencia de conexión superior para tus usuarios."
          },
          "line5": {
            "1": "4. Seguridad y Fiabilidad:",
            "2": "Confía en nuestra infraestructura de red altamente segura y confiable para asegurar la integridad y confidencialidad de tus datos."
          }
        },
        "colocation": {
          "line1": "Colocación",
          "line2": {
            "1": "1. Nivel de Infraestructura Crítica:",
            "2": "Nuestros centros de datos están diseñados para ofrecer operaciones continuas y alta disponibilidad, con sistemas de energía redundantes, refrigeración eficiente, seguridad física avanzada y conectividad de red confiable."
          },
          "line3": {
            "1": "2. Flexibilidad y Escalabilidad:",
            "2": "Elige el espacio mejor adaptado a tus necesidades. Con nuestra Colocación, puedes expandir tu infraestructura a medida que crecen tus operaciones, sin comprometer la fiabilidad o el rendimiento."
          },
          "line4": {
            "1": "3. Conectividad Premium:",
            "2": "Disfruta del acceso a una variedad de opciones de conectividad de red, incluyendo conexiones de internet de alta velocidad, conexiones directas con proveedores de servicios en la nube y acceso a intercambios de Internet (IXPs)."
          },
          "line5": {
            "1": "4. Soporte Especializado:",
            "2": "Nuestro equipo dedicado está disponible las 24 horas del día, los 7 días de la semana para proporcionar soporte técnico y asistencia cuando lo necesites. Deja que nos encarguemos de la infraestructura física del centro de datos para que puedas centrarte en lo más importante para tu negocio."
          }
        },
        "lanToLan": {
          "line1": "LAN a LAN",
          "line2": {
            "1": "1. Escalabilidad Flexible:",
            "2": "Escala tu red de acuerdo a tus necesidades de crecimiento, agregando puntos de acceso fácilmente y expandiendo tu infraestructura de manera ágil y económica."
          },
          "line3": {
            "1": "2. Experiencia Probada:",
            "2": "Con años de experiencia brindando soluciones de red de alta calidad, estamos comprometidos a ofrecer excepcionales servicios que satisfagan tus necesidades específicas."
          },
          "line4": {
            "1": "3. Personalización Adaptada a Tus Necesidades:",
            "2": "Entendemos que cada negocio es único, por lo que trabajamos de cerca contigo para diseñar una solución LAN a LAN personalizada que cumpla exactamente con tus necesidades y requisitos exclusivos."
          }
        },
        "darkFiber": {
          "line1": "Fibra Oscura",
          "line2": {
            "1": "1. Potencial Ilimitado:",
            "2": "Con nosotros, tienes la libertad de expandir y personalizar tu red de acuerdo a tus demandas. Ya sea para conexiones punto a punto o paneles de parcheo, ofrecemos una solución escalable que crece contigo."
          },
          "line3": {
            "1": "2. Seguridad y Control:",
            "2": "Mantén tus datos seguros con un control directo sobre tu infraestructura de red. Con nosotros, tienes un control total sobre tu red, proporcionando tranquilidad y seguridad para tus datos sensibles."
          }
        },
        "isp": {
          "line1": "ISP",
          "line2": {
            "1": "1. Velocidad Ultra-rápida:",
            "2": "Disfruta de impresionantes velocidades de internet capaces de soportar todas tus actividades en línea, desde streaming de video en alta definición hasta conferencias de video en grupo, sin retrasos ni interrupciones."
          },
          "line3": {
            "1": "2. Estabilidad y Fiabilidad:",
            "2": "Cuenta con una conexión a internet estable y confiable en todo momento, asegurando que puedas realizar tus tareas en línea sin preocupaciones acerca de caídas de conexión o fluctuaciones de velocidad."
          },
          "line4": {
            "1": "3. Soporte Especializado:",
            "2": "Nuestro equipo de soporte técnico está siempre listo para ayudar, garantizando una experiencia de internet fluida y satisfactoria para todos nuestros clientes."
          }
        },
        "fttx": {
          "line1": "FTTX",
          "line2": {
            "1": "1. Ubicación de Infraestructura:",
            "2": "Nuestra infraestructura de fibra óptica está diseñada para cumplir con los requerimientos más exigentes, ofreciendo estabilidad, protección contra amenazas cibernéticas, asegurando la integridad y confidencialidad de tus datos, y un rendimiento superior."
          },
          "line3": {
            "1": "2. Enlace de Alta Velocidad Dedicado:",
            "2": "Ofrecemos enlaces dedicados que garantizan máxima velocidad y rendimiento para soportar grandes volúmenes de datos y actividades intensivas, esenciales para aplicaciones críticas, ideales para empresas que dependen de una conexión rápida e interrumpida y monitoreo especializado técnico, asegurando que tu empresa siempre esté conectada."
          },
          "line4": {
            "1": "3. Reducción de Costos:",
            "2": "Evita inversiones pesadas en tu propia infraestructura y sólo paga por lo que utilizas, planes personalizados para satisfacer las necesidades específicas de tu operación."
          }
        },
        cta: 'Contrata ahora'
      },
      coverage: {
        areaDeCobertura: "Área de Cobertura",
        atendimentoNacionalEInternacional: "Servicio Nacional e Internacional"
      },
      faq: {
        line1: "Comparta su proyecto",
        line2: " con nosotros y ",
        line3: "encontraremos la solución ideal",
        line4: " para usted.",
        faqFormLabels: {
          contactText: "Contáctenos.",
          namePlaceholder: "Nombre",
          companyPlaceholder: "Empresa",
          emailPlaceholder: "Correo electrónico",
          phonePlaceholder: "Teléfono",
          messagePlaceholder: "Tu proyecto",
          submitButtonLabel: "Enviar",
          errorSubmit: "Debes completar todos los campos."
        }
        
      },
      about: {
        "title": {
          "1": " Conectando",
          "2": " el Mundo con",
          "3": " Innovación y Confianza"
        },
        "subtitle": {
          "1": " Somos Neutra Telecom",
          "2": " , donde",
          "3": " la innovación y la confianza se unen",
          "4": "  para",
          "5": " ofrecer conectividad nacional e internacional de excelencia."
        },
        "company": {
          "1": " Nuestra Compañía",
          "2": " Eramos una multinacional que, desde 2010, ha destacado por su búsqueda continua de mejora y la creación de soluciones eficientes y pioneras. Nuestro compromiso es mantener a las personas conectadas a través de la tecnología, impulsando la innovación y promoviendo el intercambio de historias, experiencias y culturas."
        },
        "mission": {
          "1": " Nuestra Misión",
          "2": " Buscamos conectar el mundo de manera confiable e innovadora. Nuestra misión es proveer comunicación de calidad y soluciones tecnológicas que inspiren confianza y simplifiquen la vida de las personas, impulsando así el progreso."
        },
        "vision": {
          "1": " Nuestra Visión",
          "2": " Nuestro objetivo es ser reconocidos como la principal operadora de telecomunicaciones, líder en tecnología y excelencia en servicios. Buscamos proporcionar una experiencia de conectividad superior, impulsada por la innovación y nuestra constante búsqueda de satisfacción del cliente."
        },
        "purpose": {
          "1": " Nuestro Propósito",
          "excellence": {
            "1": " Excelencia",
            "2": " Buscamos la excelencia en todo lo que hacemos, desde la calidad de nuestros servicios hasta el servicio al cliente."
          },
          "innovation": {
            "1": " Innovación",
            "2": " Buscamos constantemente nuevas tecnologías y soluciones para ofrecer nuestros clientes servicios de vanguardia."
          },
          "trust": {
            "1": " Confianza",
            "2": " Sólidas y transparentes, basadas en la ética. Nos comprometemos a construir relaciones profesionales, fiabilidad de nuestra red y la integridad de nuestras operaciones."
          },
          "connectivity": {
            "1": " Conectividad",
            "2": " Creemos en el poder de la comunicación para unir personas y promover el progreso social y económico."
          },
          "agility": {
            "1": " Agilidad",
            "2": " Nos adaptamos rápidamente a los cambios del mercado y las necesidades de los clientes, asegurando una respuesta rápida y efectiva en todos los aspectos de nuestros negocios."
          }
        },
        "joinUs": {
          "1": " Únete a nosotros",
          "2": " para un viaje de",
          "3": " conectividad superior",
          "4": " , donde",
          "5": " la innovación",
           6: " y la confianza impulsan el futuro digital."
        },
        "contactUs": "Contáctanos"
      },
      contact:{
        1: "Dónde ",
        2: "encontrarnos?"
      }   
    }
  }
}

export {messages}