import './App.css';
import Contact from './components/contact';
import Coverage from './components/coverage';
import Faq from './components/faq';
import Header from './components/header';
import About from './components/about'

import Home from './components/home/index';

import Services from './components/services';

function App() {
  return (
    <div id="App">
      <Header/>
      <Home/>
      <Services />
      <Coverage/>
      <About />
      <Faq/>
      <Contact/>
    </div>
  );
}

export default App;
