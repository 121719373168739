import React, { useEffect } from 'react';
import './style.css';
import closeIcon from '../../../assets/icons/Icon.png';
import i18next from 'i18next';

const Modal = ({ isOpen, onClose, content }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modalBody">
        <button className="closeButton" onClick={onClose} style={{ backgroundImage: `url(${closeIcon})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain', width: '30px', height: '30px' }}></button>
        <div style={{
          borderRadius: '5px',
          color: 'white',
          overflowY: 'auto',
          display: 'flex',
          fontSize: '50px',
          justifyContent: 'center',
          fontFamily: 'sans-serif',
          width: '85%',
          margin: 'auto',
          marginBottom: '100px',
          marginTop: '50px'
        }}>
          {content}
        </div>
        <div id="buttonBox">
          <button id='ctaButton' className='buttonModal'><a href="https://wa.me/5515998556000" target="_blank" rel="noopener noreferrer" >{i18next.t('services.cta')}</a></button>
        </div>  
      </div>
    </div>
  );
};

export default Modal;