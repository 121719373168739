const messages = {
  en: {
    translations: {
      header: {
        menus: {
          home: 'Home',
          services: 'Services',
          coverage: 'Coverage',
          about: 'About',
          faq: 'FAQ',
          contact: 'Contact'
        },
        " logo" : {
          " description_image" : " This is the logo of Neutra Telecom" 
        },
        " description_pt_image" : " This is the flag of Brazil" ,
        " description_spa_image" : " This is the flag of Spain" ,
        " description_en_image" : " This is the flag of the United States" 
      },
      home: {
        "text" : {
          "h1" : {
            "line1" : {
              " 1": "Neutra Telecom"
            },
            "line2": {
              "1": "Innovation",
              "2": "that drives and"
            },
            "line3": {
              "1": "connects."
            },
            "line4": {
              "1": "Technology",
              "2": "that",
              "3": "brings closer."
            }
          },
          "p1": {
            "line1": {
              "1": "Global connectivity",
              "2": "for your business discover our services",
              "3": "leaders in"
            },
            "line2": {
              "1": "IP transit",
              "2": "international",
              "3": "and more. Reach"
            },
            "line3": {
              "1": "new horizons now!"
            }
          },
          "p2": {
            "line1": {
              "1": "Global connectivity",
              "2": "for your business "
            },
            "line2": {
              "1": "discover our services",
              "2": "leaders in IP transit",
            },
            "line3": {
              "1": "international",
              "2": "and more."
            },
            "line4": {
              "1": "Reach new horizons now!"
            }
          }
        },
        button: {
          textButton: 'Get in touch'
        }
      },
      services: {
        "line1": {
          "1": "Connections",
          "2": "B2B",
          "3": "Customized"
        },
        sdWan: {
          line1: 'SD-WAN',
          line2: {
            '1': '1. Agility and Flexibility:',
            '2': 'Simplify network management and gain agility to quickly adapt to changes in your company\'s connectivity needs.'
          },
          line3: {
            '1': '2. Optimized Performance:',
            '2': 'Ensure optimal performance for critical applications, directing traffic intelligently and dynamically based on real-time network conditions.'
          },
          line4: {
            '1': '3. Cost Savings:',
            '2': 'Reduce connectivity costs using a variety of network links, such as internet, MPLS, 4G, and 5G. Advanced Security: Protect your network against cyber threats with integrated advanced security features, keeping your data and information safe at all times.'
          }
        },
        transitoIp: {
          line1: 'Transit IP',
          line2: {
            '1': '1. Exceptional Performance:',
            '2': 'Maximize your network performance with redundant routes and low latency, ensuring a superior connection experience for your users anywhere in the world.'
          },
          line3: {
            '1': '2. Flexible Scalability:',
            '2': 'Scale your infrastructure according to your business demands, ensuring that your network is always ready to grow as your needs evolve.'
          },
          line4: {
            '1': '3. Specialized Support:',
            '2': 'Rely on our team of experts to provide personalized global support and ensure that your infrastructure operates efficiently and reliably.'
          }
        },
        datacenter: {
          line1: 'Datacenter',
          line2: {
            '1': '1. Global Connectivity:',
            '2': 'Leverage our network to connect your businesses and needs across different parts of the world, ensuring fast and secure data transfer between your global operations.'
          },
          line3: {
            '1': '2. Access to Major Datacenters:',
            '2': 'Have direct access to the worlds major datacenters with our network, enabling you to reach new markets and take advantage of top-tier cloud computing infrastructures and hosting services.'
          },
          line4: {
            '1': '3. Connection to PTT:',
            '2': 'Through our network, we offer direct connection to major PTTs (Point of Traffic Exchange), ensuring efficient data routing and a superior connection experience for your users.'
          },
          line5: {
            '1': '4. Security and Reliability:',
            '2': 'Rely on our highly secure and reliable network infrastructure to ensure the integrity and confidentiality of your data.'
          }
        },
        colocation: {
          line1: 'Colocation',
          line2: {
            '1': '1. Critical Infrastructure Level:',
            '2': 'Our data centers are designed to offer continuous operations and high availability, with redundant power systems, efficient cooling, advanced physical security, and reliable network connectivity.'
          },
          line3: {
            '1': '2. Flexibility and Scalability:',
            '2': 'Choose the space best suited to your needs. With our Colocation, you can expand your infrastructure as your operations grow, without compromising reliability or performance.'
          },
          line4: {
            '1': '3. Premium Connectivity:',
            '2': 'Enjoy access to a variety of network connectivity options, including high-speed internet connections, direct connections with cloud service providers, and access to Internet exchanges (IXPs).'
          },
          line5: {
            '1': '4. Specialized Support:',
            '2': 'Our dedicated team is available 24/7 to provide technical support and assistance whenever you need it. Let us handle the physical data center infrastructure so you can focus on whats most important for your business.'
          }
        },
        lanToLan: {
          line1: 'LAN to LAN',
          line2: {
            '1': '1. Flexible Scalability:',
            '2': 'Scale your network as per your growth needs, adding new access points easily and expanding your infrastructure agilely and economically.'
          },
          line3: {
            '1': '2. Proven Experience:',
            '2': 'With years of experience providing high-quality network solutions, we are committed to offering exceptional services that meet your specific needs.'
          },
          line4: {
            '1': '3. Customization Tailored to Your Needs:',
            '2': 'We understand that every business is unique, so we work closely with you to design a customized LAN TO LAN solution that meets exactly your needs and exclusive requirements.'
          }
        },
        darkFiber: {
          line1: 'Dark Fiber',
          line2: {
            '1': '1. Unlimited Potential:',
            '2': 'With us, you have the freedom to expand and personalize your network according to your demands. Whether for point-to-point connections or patch panels, we offer a scalable solution that grows with you.'
          },
          line3: {
            '1': '2. Security and Control:',
            '2': 'Keep your data safe with direct control over your network infrastructure. With us, you have complete control over your network, providing peace of mind and security for your sensitive data.'
          }
        },
        isp: {
          line1: 'ISP',
          line2: {
            '1': '1. Ultra-fast Speed:',
            '2': 'Enjoy impressive internet speeds capable of supporting all your online activities, from high-definition video streaming to group video conferencing, without delays or interruptions.'
          },
          line3: {
            '1': '2. Stability and Reliability:',
            '2': 'Count on a stable and reliable internet connection at all times, ensuring you can perform your online tasks without worries about connection drops or speed fluctuations.'
          },
          line4: {
            '1': '3. Specialized Support:',
            '2': 'Our team of technical support is always ready to help, guaranteeing a smooth and satisfying internet experience for all our clients.'
          }
        },
        fttx: {
          line1: 'FTTX',
          line2: {
            '1': '1. Infrastructure Location:',
            '2': 'Our fiber optic infrastructure is designed to meet the most demanding requirements, offering stability, protection against cyber threats, ensuring the integrity and confidentiality of your data, and superior performance.'
          },
          line3: {
            '1': '2. Dedicated High-Speed Link:',
            '2': 'We offer dedicated links that guarantee maximum speed and performance to support large volumes of data and intensive activities, essential for critical applications, ideal for companies that rely on a quick and uninterrupted internet connection and 24/7 monitoring with specialized technical support, ensuring that your company is always connected.'
          },
          line4: {
            '1': '3. Cost Reduction:',
            '2': 'Avoid heavy investments in your own infrastructure and only pay for what you use, custom plans to meet the specific needs of your operation.'
          },
      },
              cta: 'Hire now'
      },
      coverage: {
        areaDeCobertura: "Coverage Area",
        atendimentoNacionalEInternacional: "National and International Service"
      },
      faq: {
        line1: "Share your project ",
        line2: "with us and ",
        line3: "we will find the ideal solution ",
        line4: "for you.",
        faqFormLabels: {
          contactText: "Get in touch.",
          namePlaceholder: "Name",
          companyPlaceholder: "Company",
          emailPlaceholder: "Email",
          phonePlaceholder: "Phone",
          messagePlaceholder: "Your project",
          submitButtonLabel: "Send",
          errorSubmit: "You must fill in all the fields."
        }       
        
      },
      about: {
        "title": {
          "1": " Connecting ",
          "2": " the World with ",
          "3": " Innovation and Trust "
        },
        "subtitle": {
          "1": " We are Neutra Telecom ",
          "2": " , where ",
          "3": " innovation and trust unite ",
          "4": "  to ",
          "5": " offer national and international connectivity of excellence."
        },
        "company": {
          "1": " Our Company ",
          "2": " We are a multinational that, since 2010, has distinguished itself by its continuous pursuit of improvement and the creation of efficient and pioneering solutions. Our commitment is to keep people connected through technology, driving innovation and promoting the sharing of stories, experiences, and cultures."
        },
        "mission": {
          "1": " Our Mission ",
          "2": " We seek to connect the world reliably and innovatively. Our mission is to provide quality communication and technological solutions that inspire confidence and simplify people's lives, thus driving progress."
        },
        "vision": {
          "1": " Our Vision ",
          "2": " Our goal is to be recognized as the leading telecommunications operator, leader in technology and excellence in services. We strive to provide a superior connectivity experience, driven by innovation and our constant quest for customer satisfaction."
        },
        "purpose": {
          "1": " Our Purpose ",
          "excellence": {
            "1": " Excellence ",
            "2": " We seek excellence in everything we do, from the quality of our services to customer service."
          },
          "innovation": {
            "1": " Innovation ",
            "2": " We constantly seek new technologies and solutions to offer our customers state-of-the-art services."
          },
          "trust": {
            "1": " Trust ",
            "2": " Solid and transparent, based on ethics. We commit to building professional relationships, network reliability, and integrity in our operations."
          },
          "connectivity": {
            "1": " Connectivity ",
            "2": " We believe in the power of communication to bring people together and promote social and economic progress."
          },
          "agility": {
            "1": " Agility ",
            "2": " We quickly adapt to market changes and customer needs, ensuring a quick and effective response in all aspects of our business."
          }
        },
        "joinUs": {
          "1": " Join us ",
          "2": " for a journey of ",
          "3": " superior connectivity ",
          "4": " , where ",
          "5": " innovation",
           6: "and trust drive the future of digital."
        },
        "contactUs": " Contact Us"
      },
      contact:{
        1: "Where to ",
        2: "find us?"
      }      
    }
  }
}


export {messages}