import i18next from "i18next"
import "./style.css"

function FaqTextLeft(){

  return(
    <>
      <div id="faqTextLeftMain">
        <p>
        <span className='blue'>{i18next.t('faq.line1')}</span> 
          {i18next.t('faq.line2')}
          <span className='blue'> {i18next.t('faq.line3')}
          </span> {i18next.t('faq.line4')}
        </p>
      </div>
    </>
  )
}

export default FaqTextLeft