import './style.css'
import { i18n } from "../../../translate/i18n"

function Button(){
  return(
    <>
      <div id="buttonBox">
        <button id='ctaButton'><a href="https://wa.me/5515998556000" target="_blank" rel="noopener noreferrer" >{i18n.t('home.button.textButton')}</a></button>
      </div>  
    </>
  )
}

export default Button