const messages = {
  pt: {
    translations: {
      header: {
        menus: {
          home: 'Home',
          services: 'Serviços',
          coverage: 'Cobertura',
          about: 'Sobre',
          faq: 'FAQ',
          contact: 'Contato'
        },
        logo: {
          description_image: 'Está é a logo da Neutra Telecom'
        },
        description_pt_image: 'Está é a bandeira do Brasil',
        description_spa_image: 'Está é a bandeira da Espanha',
        description_en_image: 'Está é a bandeira do Estados Unidos',
      },
      home: {
        text: {
          h1: {
            line1: {
              1: 'Neutra Telecom'
            },
            line2: {
              1: 'Inovação',
              2: 'que impulsiona e'
            },
            line3: {
              1: 'conecta.'
            },
            line4: {
              1: 'Tecnologia',
              2: 'que',
              3: 'aproxima.'
            }
          },
          p1: {
            line1: {
              1: 'Conectividade global',
              2: 'para seu negócio descubra nossos serviços',
              3: 'líderes em'
            },
            line2: {
              1: 'trânsito IP',
              2: 'internacional',
              3: 'e mais. Alcance'
            },
            line3: {
              1: 'novos horizontes agora!'
            }
          },
          p2: {
            line1: {
              1: 'Conectividade global',
              2: 'para seu negócio '
            },
            line2: {
              1: 'descubra nossos serviços',
              2: 'líderes em trânsito IP',
            },
            line3: {
              1: 'internacional',
              2: 'e mais.'
            },
            line4: {
              1: 'Alcance novos horizontes agora!'
            }
          },
        },
        button: {
          textButton: 'Entrar em contato'
        }
      },
      services: {
        line1: {
          '1': 'Conexões',
          '2': 'B2B',
          '3': 'personalizadas'
        },
        sdWan: {
          line1: 'SD-WAN',
          line2: {
            '1': '1. Agilidade e Flexibilidade:',
            '2': 'Simplifique a gestão da sua rede e ganhe agilidade para adaptar-se rapidamente às mudanças nas necessidades de conectividade da sua empresa.'
          },
          line3: {
            '1': '2. Desempenho Otimizado:',
            '2': 'Garanta o melhor desempenho para seus aplicativos críticos, direcionando o tráfego de forma inteligente e dinâmica, com base nas condições da rede em tempo real.'
          },
          line4: {
            '1': '3. Economia de Custos:',
            '2': 'Reduza os custos de conectividade utilizando uma variedade de links de rede, como internet, MPLS, 4G e 5G. Segurança Avançada: Proteja sua rede contra ameaças cibernéticas com recursos avançados de segurança integrados, mantendo seus dados e informações seguros em todos os momentos.'
          }
        },
        transitoIp: {
          line1: 'Trânsito IP',
          line2: {
            '1': 'Desempenho Excepcional:',
            '2': 'Maximize o desempenho da sua rede com rotas redundantes e de baixa latência, garantindo uma experiência de conexão superior para seus usuários em qualquer lugar do mundo.'
          },
          line3: {
            '1': 'Escalabilidade Flexível:',
            '2': 'Dimensione sua infraestrutura de acordo com as demandas do seu negócio, garantindo que sua rede esteja sempre pronta para crescer conforme suas necessidades evoluem.'
          },
          line4: {
            '1': 'Suporte Especializado:',
            '2': 'Conte com nossa equipe de especialistas para fornecer suporte técnico personalizado de nivel global e garantir que sua infraestrutura opere de forma eficiente e confiável.'
          }
        },
        datacenter: {
          line1: 'Datacenter',
          line2: {
            '1': 'Conexão Global:',
            '2': 'Conte com nossa rede para interligar seus negocios e necessidades em diferentes partes do mundo, garantindo uma transferência rápida e segura de dados entre suas operações globais.'
          },
          line3: {
            '1': 'Acesso aos Principais Datacenters:',
            '2': 'Tenha acesso direto aos principais datacenters do mundo com nossa rede, permitindo que você alcance novos mercados e aproveite as melhores infraestruturas de computação em nuvem e serviços de hospedagem.'
          },
          line4: {
            '1': 'Conexão ao PTT:',
            '2': 'Através da nossa rede, oferecemos conexão direta aos principais PTTs (Ponto de Troca de Tráfego), garantindo o roteamento eficiente de dados e uma experiência de conectividade superior para seus usuários.'
          },
          line5: {
            '1': 'Segurança e Confiabilidade:',
            '2': 'Conte com nossa infraestrutura de rede altamente segura e confiável para garantir a integridade e confidencialidade dos seus dados.'
          }
        },
        colocation: {
          line1: 'Colocation',
          line2: {
            '1': 'Infraestrutura de Nível Crítico:',
            '2': 'Nossos data centers são projetados para oferecer operações contínuas e de altadisponibilidade, com sistemas de energia redundantes, refrigeração eficiente, segurança física avançada econectividade de rede confiável.'
          },
          line3: {
            '1': 'Flexibilidade e Escalabilidade:',
            '2': 'Escolha o espaço quemelhor atenda às suas necessidades. Com nossa Colocation,você pode expandir sua infraestrutura conforme suas operações crescem, sem comprometer a confiabilidadeou o desempenho.'
          },
          line4: {
            '1': 'Conectividade Premium:',
            '2': 'Desfrute de acesso a uma variedade de opções de conectividade de rede, incluindoconexões de internet de alta velocidade, conexões diretascom provedores de serviços de nuvem e acesso a trocasde internet (IXPs).'
          },
          line5: {
            '1': 'Suporte Especializado:',
            '2': 'Nossa equipe dedicada está disponível 24/7 para fornecer suporte técnico e assistênciasempre que você precisar. eixe-nos cuidar da infrastruturafísica do data center para que você possa se concentrar noque é mais importante para o seu negócio.'
          }
        },
        lanToLan: {
          line1: 'Lan-To-Lan',
          line2: {
            '1': 'Escalabilidade Flexível:',
            '2': 'Adapte sua rede conforme suas necessidades de crescimento, adicionando facilmente novos pontos de acesso e expandindo sua infraestrutura de forma ágil e econômica.'
          },
          line3: {
            '1': 'Experiência Comprovada:',
            '2': 'Com anos de experiência em fornecer soluções de rede de alta qualidade, estamos comprometidos em oferecer serviços excepcionais que atendam às suas necessidades específicas.'
          },
          line4: {
            '1': 'Personalização Sob Medida:',
            '2': 'Entendemos que cada negócio é único, por isso trabalhamos em estreita colaboração com você para projetar uma solução LAN TO LAN personalizada que atenda exatamente às suas necessidades e requisitos exclusivos.'
          }
        },
        darkFiber: {
          line1: 'Dark-Fiber',
          line2: {
            '1': 'Potencial Ilimitado:',
            '2': 'Com a gente, você tem a liberdade de expandir epersonalizar sua rede de acordo com suas demandas. Seja para conexões pontoa ponto ou caixas de emenda, oferecemos uma solução escalável que crescecom você.'
          },
          line3: {
            '1': 'Segurança e Controle:',
            '2': 'Mantenha seus dados seguros com o controle diretsobre sua infraestrutura de rede. om a gente, você tem total controle sobre suarede, proporcionando tranquilidade e segurança para seus dados sensíveis.'
          }
        },
        isp: {
          line1: 'ISP',
          line2: {
            '1': 'Velocidade Ultra-rápida:',
            '2': 'Desfrute de velocidades de internet impressionantes, capazes de suportar todas as suas atividades online, desde streaming de vídeo em alta definição até videoconferências em grupo, sem atrasos ou interrupções.'
          },
          line3: {
            '1': 'Estabilidade e Confiabilidade:',
            '2': 'Conte com uma conexão de internet estável e confiável em todos os momentos, garantindo que você possa realizar suas tarefas online sem preocupações com quedas de conexão ou oscilações de velocidade.'
          },
          line4: {
            '1': 'Suporte Especializado:',
            '2': 'Nossa equipe de suporte técnico está sempre pronta para ajudar, garantindo uma experiência de internet suave e satisfatória para todos os nossos clientes.'
          }
        },
        fttx: {
          line1: 'FTTx',
          line2: {
            '1': 'Locação de infraestrutura:',
            '2': 'Nossa infraestrutura de fibra óptica é projetada para atender às demandas mais rigorosas, oferecendo estabilidade, segurançacontra ameaças cibernéticas, assegurando a integridade e a confidencialidadedos seus dados, e desempenho superior.'
          },
          line3: {
            '1': 'Link dedicado de alta velocidade:',
            '2': 'Oferecemos links dedicados que garantama máxima velocidade e performance para suportar grandes volumes dedados e atividades intensivas, baixa latência que é essencial para aplicaçõescríticas, ideal para empresas que dependem de uma conexão de internet rápida e ininterrupta e monitoramento 24/7 com suporte técnico especializado,assegurando que sua empresa esteja sempre conectada.'
          },
          line4: {
            '1': 'Redução de custos:',
            '2': 'Evite investimentos pesados em infraestrutura própria e pague apenas pelo que utiliza, planos personalizados para atender àsnecessidades específicas da sua operação.'
          }
        },
        cta: 'Contrate já'
      },
      coverage: {
        areaDeCobertura: "Área de Cobertura",
        atendimentoNacionalEInternacional: "Atendimento Nacional e Internacional"
      },
      faq:{
        line1: 'Compartilhe seu projeto ',
        line2: 'conosco e ',
        line3: 'encontraremos a solução ideal',
        line4: 'para você.',
        faqFormLabels: {
          contactText: "Entrar em contato.",
          namePlaceholder: "Nome",
          companyPlaceholder: "Empresa",
          emailPlaceholder: "Email",
          phonePlaceholder: "Telefone",
          messagePlaceholder: "Seu projeto",
          submitButtonLabel: "Enviar",
          errorSubmit: "Você deve preencher todos os campos."
        }
      },
      about: {
        title:{
          1: "Conectando", 2: " o Mundo com", 3: "Inovação e Confiança",
        },
        subtitle:{
         1: "Somos à Neutra Telecom", 2:", onde a ", 3:"inovação e a confiança se unem", 4:" para",5:" oferecer conectividade nacional e internacional de excelência.",

        },
        company: {
          1: "Nossa Empresa",
          2: "Somos uma multinacional que, desde 2010, tem se destacado pela busca contínua por aprimoramento e pela criação de soluções eficientes e pioneiras. Nosso compromisso é manter as pessoas conectadas por meio da tecnologia, impulsionando a inovação e promovendo o compartilhamento de histórias, experiências e culturas."
        },
        mission: {
          1: "Nossa Missão",
          2: "Buscamos conectar o mundo de maneira confiável e inovadora. Nossa missão é fornecer comunicação de qualidade e soluções tecnológicas que inspirem confiança e simplifiquem a vida das pessoas, impulsionando assim o progresso."
        },
        vision: {
          1: "Nossa Visão",
          2: "Nosso objetivo é que sejamos reconhecidos como a principal operadora de telecomunicações, líder em tecnologia e excelência em serviços. Buscamos proporcionar uma experiência de conectividade superior, impulsionada pela inovação e pela nossa constante busca pela satisfação do cliente."
        },
        purpose: {
          1: "Nosso Propósito",
          excellence: {
            1: "Excelência",
            2: "Buscamos a excelência em tudo o que fazemos, desde a qualidade de nossos serviços até o atendimento ao cliente."
          },
          innovation: {
            1: "Inovação",
            2: "Buscamos constantemente novas tecnologias e soluções para oferecer serviços de última geração aos nossos clientes."
          },
          trust: {
            1: "Confiança",
            2: "Sólidas e transparentes, baseadas na ética. Comprometemo-nos a construir relações profissionais, confiabilidade de nossa rede e na integridade de nossas operações."
          },
          connectivity: {
            1: "Conectividade",
            2: "Acreditamos no poder da comunicação para unir pessoas e promover o progresso social e econômico."
          },
          agility: {
            1: "Agilidade",
            2: "Adaptamo-nos rapidamente às mudanças do mercado e às necessidades dos clientes, garantindo uma resposta rápida e eficaz em todos os aspectos de nossos negócios."
          }
        },
        joinUs:{
          1:"Junte-se",2:" a nós para uma jornada de",3:" conectividade superior",4:", onde a",5:" inovação ",6:" e a confiança impulsionam o futuro digital.",
        },
        contactUs: "Fale Conosco"
      },
      contact:{
        1: "Onde nos ",
        2: "encontrar?"
      } 
    }
  }
}


export {messages}