import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Modal from './modal';
import './style.css'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import datacenterImage from './carrousel/images/6.png';
import transitoIpImage from './carrousel/images/7.png';
import sdWanImage from './carrousel/images/8.png';
import colocationImage from './carrousel/images/9.png';
import lanImage from './carrousel/images/10.png';
import darkFiberImage from './carrousel/images/11.png';
import ispImage from './carrousel/images/12.png';
import fttxImage from './carrousel/images/13.png';
import i18next from 'i18next';


function Services(){
  const [slidesPerView, setSlidesPerView] = useState(3)
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const data = [
    {
      id: '1',
      image: sdWanImage,
      title: '',
      modalContent: (
        <div>
          <div className="titleModal">{i18next.t('services.sdWan.line1')}</div>
          <p className='contentModal'>
            <strong className="highlight">{i18next.t('services.sdWan.line2.1')}</strong> {i18next.t('services.sdWan.line2.2')}.
            <br /><br />
            <strong className="highlight">{i18next.t('services.sdWan.line3.1')}</strong> {i18next.t('services.sdWan.line3.2')}.
            <br /><br /><br />
            <strong className="highlight">{i18next.t('services.sdWan.line4.1')}</strong> {i18next.t('services.sdWan.line4.2')}.
            <br />
          </p>
        </div>
      ),
    },
    {
      id: '2',
      image: transitoIpImage,
      title: '',
      modalContent: (
        <div>
          <div className="titleModal">{i18next.t('services.transitoIp.line1')}</div>
          <p className='contentModal'>
            <strong className="highlight">{i18next.t('services.transitoIp.line2.1')}</strong> {i18next.t('services.transitoIp.line2.2')}.
            <br /><br />
            <strong className="highlight">{i18next.t('services.transitoIp.line3.1')}</strong> {i18next.t('services.transitoIp.line3.2')}.
            <br /><br />
            <strong className="highlight">{i18next.t('services.transitoIp.line4.1')}</strong> {i18next.t('services.transitoIp.line4.2')}.
            <br />
          </p>
        </div>
      ),
    },
    {
      id: '3',
      image: datacenterImage,
      title: '',
      modalContent: (
        <div>
          <div className="titleModal">{i18next.t('services.datacenter.line1')}</div>
          <p className='contentModal'>
            <strong className="highlight">{i18next.t('services.datacenter.line2.1')}</strong> {i18next.t('services.datacenter.line2.2')}.
            <br /><br />
            <strong className="highlight">{i18next.t('services.datacenter.line3.1')}</strong> {i18next.t('services.datacenter.line3.2')}.
            <br /><br />
            <strong className="highlight">{i18next.t('services.datacenter.line4.1')}</strong> {i18next.t('services.datacenter.line4.2')}.
            <br />
          </p>
        </div>
      ),
    },
    {
      id: '4',
      image: colocationImage,
      title: '',
      modalContent: (
        <div>
          <div className="titleModal">{i18next.t('services.colocation.line1')}</div>
          <p className='contentModal'>
            <strong className="highlight">{i18next.t('services.colocation.line2.1')}</strong> {i18next.t('services.colocation.line2.2')}.
            <br /><br />
            <strong className="highlight">{i18next.t('services.colocation.line3.1')}</strong> {i18next.t('services.colocation.line3.2')}.
            <br /><br />
            <strong className="highlight">{i18next.t('services.colocation.line4.1')}</strong> {i18next.t('services.colocation.line4.2')}.
            <br />
          </p>
        </div>
      ),
    },
    {
      id: '5',
      image: lanImage,
      title: '',
      modalContent: (
        <div>
          <div className="titleModal">{i18next.t('services.lanToLan.line1')}</div>
          <p className='contentModal'>
            <strong className="highlight">{i18next.t('services.lanToLan.line2.1')}</strong> {i18next.t('services.lanToLan.line2.2')}.
            <br /><br />
            <strong className="highlight">{i18next.t('services.lanToLan.line3.1')}</strong> {i18next.t('services.lanToLan.line3.2')}.
            <br /><br />
            <strong className="highlight">{i18next.t('services.lanToLan.line4.1')}</strong> {i18next.t('services.lanToLan.line4.2')}.
            <br />
          </p>
        </div>
      ),
    },
    {
      id: '6',
      image: darkFiberImage,
      title: '',
      modalContent: (
        <div>
          <div className="titleModal">{i18next.t('services.darkFiber.line1')}</div>
          <p className='contentModal'>
            <strong className="highlight">{i18next.t('services.darkFiber.line2.1')}</strong> {i18next.t('services.darkFiber.line2.2')}.
            <br /><br />
            <strong className="highlight">{i18next.t('services.darkFiber.line3.1')}</strong> {i18next.t('services.darkFiber.line3.2')}.
            <br />
          </p>
        </div>
      ),
    },
    {
      id: '7',
      image: ispImage,
      title: '',
      modalContent: (
        <div>
          <div className="titleModal">{i18next.t('services.isp.line1')}</div>
          <p className='contentModal'>
            <strong className="highlight">{i18next.t('services.isp.line2.1')}</strong> {i18next.t('services.isp.line2.2')}.
            <br /><br />
            <strong className="highlight">{i18next.t('services.isp.line3.1')}</strong> {i18next.t('services.isp.line3.2')}.
            <br />
          </p>
        </div>
      ),
    },
    {
      id: '8',
      image: fttxImage,
      title: '',
      modalContent: (
        <div>
          <div className="titleModal">{i18next.t('services.fttx.line1')}</div>
          <p className='contentModal'>
            <strong className="highlight">{i18next.t('services.fttx.line2.1')}</strong> {i18next.t('services.fttx.line2.2')}.
            <br /><br />
            <strong className="highlight">{i18next.t('services.fttx.line3.1')}</strong> {i18next.t('services.fttx.line3.2')}.
            <br /><br />
            <strong className="highlight">{i18next.t('services.fttx.line4.1')}</strong> {i18next.t('services.fttx.line4.2')}.
            <br />
          </p>
        </div>
      ),
    }
    
  ];

  const openModal = (item) => {
    setModalContent(item.modalContent);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect (() => {
    function handleResize() {

      if(window.innerWidth < 720) {
        setSlidesPerView(1);
      } else if(window.innerWidth >= 720 && window.innerWidth <= 991 ) {
        setSlidesPerView(2);
      } else {
        setSlidesPerView(3);
      }
    }
    handleResize();

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return(
    <div>
      <div id='myBody'>
        <div className='container'>
          <div className='titleCarrousel'>
            <div className='listra'/>
            <h2>{i18next.t('services.line1.1')} <span className="text-blue">{i18next.t('services.line1.2')}</span> {i18next.t('services.line1.3')}.</h2>
          </div>
          <div className='carrousel'>
            <Swiper
                onSlideChange={(swiper) => {}}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={slidesPerView}
                navigation
                loop
                // pagination={{ clickable: true, bulletClass: 'custom-bullet', bulletActiveClass: 'custom-bullet-active' }}
                scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                >
                {data.map( (item) => (
                  <SwiperSlide key={item.id}>
                  <div className="slide-container" >
                    <img src={item.image} alt={item.title} className='slide-image' onClick={() => openModal(item)} style={{ cursor: 'pointer' }} />
                    <div className="slide-title">{item.title}</div>
                  </div>
                  </SwiperSlide>
                ))}
            </Swiper>
            <Modal isOpen={modalOpen} onClose={closeModal} content={modalContent} />
          </div>
          <div className='UnderTitleCarrousel'>
            <h2>{i18next.t('services.line1.1')} <span className="text-blue">{i18next.t('services.line1.2')}</span> {i18next.t('services.line1.3')}.</h2>
            <div className='listra'/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services