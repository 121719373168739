import './style.css'
import videoBackgroundHomeSrc from "../../../assets/videos/backgroundHome.mp4"

function VideoBackgroundHome(){
  return(
    <>
      <div id="boxVideoBackgroundHome">
        <video id='videoBackgroundHome'autoPlay muted loop playsInline> 
          <source src={videoBackgroundHomeSrc} type='video/mp4'/>
        </video>
      </div>
    </>
  )
}

export default VideoBackgroundHome