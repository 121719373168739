import './style.css';
import imgCoverageMap from "../../assets/images/coverage-map.png";
import i18next from 'i18next';

function Coverage() {
  return (
    <>
    <div  id="coverageBox">
      <div id="coverageMain">
        <div className="textCoverage" id='textCoverage1'>
          <p>{i18next.t('coverage.areaDeCobertura')}</p>
        </div>
        <img src={imgCoverageMap} alt="Mapa de Cobertura" id='coverageMapImg'/>
        <div className="textCoverage" id='textCoverage2'>
          <p>{i18next.t('coverage.atendimentoNacionalEInternacional')}</p>
        </div>
      </div>
    </div>
    </>
  );
}

export default Coverage;
