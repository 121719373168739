import './style.css'

import imgEmail from "../../assets/images/@.png";
import imgInsta from "../../assets/images/instagram.png";
import imgWhats from "../../assets/images/whats.png";
import i18next from 'i18next';


function Contact(){
  return(
    <>
      <div  id="contactBox">
        <div id="contactMain">
          <div id='contactHead'>
            <div className='contactDivider1'/>
            <div id="headerText">
              <p>
                {i18next.t('contact.1')}<span>{i18next.t('contact.2')}</span>
              </p>
            </div>
  <div className='contactDivider2'/>
          </div>
          <div id='mainContact'>
            <div className='boxContactOutput' id="boxContactOutput1">
              <img alt='teste' src={imgEmail}/>
              <p><a href="mailto:atendimento@neutratelecom.com?subject=Assunto%20do%20Email&body=Corpo%20do%20email">atendimento@neutratelecom.com</a></p>
            </div>
            <div className='boxContactOutput' id="boxContactOutput2">
              <img alt='teste' src={imgWhats}/>
              <p><a href="https://wa.me/5515998556000" target="_blank" rel="noopener noreferrer" >+55 15 99855-6000</a></p>
            </div>
            <div className='boxContactOutput' id="boxContactOutput3">
              <img alt='teste' src={imgInsta}/>
              <p><a href="https://www.instagram.com/neutratelecom/" target="_blank" rel="noopener noreferrer">@neutratelecom</a></p>
            </div>
          </div>
          <div id='contactFooter'>
            <div className='contactDivider'/>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact