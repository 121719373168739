import VideoBackgroundHome from './backgroundVideo';
import Button from './button';
import './style.css'
import TextHome from './text';

function Home(){
  return(
    <>
      <div id="homeBox">
        <main id="homeMain">
          <TextHome/>
          <Button/>
        </main>
      </div>  
      <VideoBackgroundHome/>
    </>
  )
}

export default Home