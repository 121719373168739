import React, { useState, useEffect } from 'react';
import './style.css';
import arrow from '../../assets/icons/IconArrow.png'
import i18next from 'i18next';

function About(){

  const [showMission, setShowMission] = useState(false);
  const [showValues, setShowValues] = useState(false);
  const [showCompany, setShowCompany] = useState(false);
  const [showPurpose, setShowPurpose] = useState(false);
  const [animateCloseMission, setAnimateCloseMission] = useState(false);
  const [animateCloseValues, setAnimateCloseValues] = useState(false);
  const [animateCloseCompany, setAnimateCloseCompany] = useState(false);
  const [animateClosePurpose, setAnimateClosePurpose] = useState(false);

  const toggleMission = () => {
    if (!showMission) {
      closeAllExcept('mission');
      setTimeout(() => {
        setShowMission(true);
        setAnimateCloseMission(false);
      }, 300);
    } else {
      setAnimateCloseMission(true);
      setTimeout(() => {
        setShowMission(false);
        setAnimateCloseMission(false);
      }, 300);
    }
  };

  const toggleValues = () => {
    if (!showValues) {
      closeAllExcept('values');
      setTimeout(() => {
        setShowValues(true);
        setAnimateCloseValues(false);
      }, 300);
    } else {
      setAnimateCloseValues(true);
      setTimeout(() => {
        setShowValues(false);
        setAnimateCloseValues(false);
      }, 300);
    }
  };

  const toggleCompany = () => {
    if (!showCompany) {
      closeAllExcept('company');
      setTimeout(() => {
        setShowCompany(true);
        setAnimateCloseCompany(false);
      }, 300);
    } else {
      setAnimateCloseCompany(true);
      setTimeout(() => {
        setShowCompany(false);
        setAnimateCloseCompany(false);
      }, 300);
    }
  };

  const togglePurpose = () => {
    if (!showPurpose) {
      closeAllExcept('purpose');
      setTimeout(() => {
        setShowPurpose(true);
        setAnimateClosePurpose(false);
      }, 300);
    } else {
      setAnimateClosePurpose(true);
      setTimeout(() => {
        setShowPurpose(false);
        setAnimateClosePurpose(false);
      }, 300);
    }
  };

  const closeAllExcept = (current) => {
    if (current !== 'mission') {
      setShowMission(false);
      setAnimateCloseMission(true);
    }
    if (current !== 'values') {
      setShowValues(false);
      setAnimateCloseValues(true);
    }
    if (current !== 'company') {
      setShowCompany(false);
      setAnimateCloseCompany(true);
    }
    if (current !== 'purpose') {
      setShowPurpose(false);
      setAnimateClosePurpose(true);
    }
  };

  useEffect(() => {
    let timeoutId;
    if (animateCloseMission || animateCloseValues || animateCloseCompany || animateClosePurpose) {
      timeoutId = setTimeout(() => {
        setAnimateCloseMission(false);
        setAnimateCloseValues(false);
        setAnimateCloseCompany(false);
        setAnimateClosePurpose(false);
      }, 300); 
    }
    return () => clearTimeout(timeoutId);
  }, [animateCloseMission, animateCloseValues, animateCloseCompany, animateClosePurpose]);


  return(
    <>
    <div id='aboutBackground'>
      <div id='aboutBox'>
        <div id='aboutMain'>
          <div id="boxTextAbout">
            <h1 id="h1About" className="text">
              <span className='blue'>{i18next.t('about.title.1')}</span>{i18next.t('about.title.2')}<br/>
              <span className='blue'>{i18next.t('about.title.3')}</span><br/>
            </h1>
            <p id="pAbout" className="text">
              <span className='blue'>{i18next.t('about.subtitle.1')}</span>{i18next.t('about.subtitle.2')}<span className='blue'>{i18next.t('about.subtitle.3')}</span>{i18next.t('about.subtitle.4')}<br/>
              <span>{i18next.t('about.subtitle.5')}</span><br/>
            </p>
          </div>
          <div>
          <div id='boxInfos'>
              <div>
                <button className="arrowButton" onClick={toggleCompany}>
                  <img src={arrow} alt="arrow" className={`arrow ${showCompany ? 'rotate' : 'animate-close'}`} />
                </button>
              </div>
              <div className="boxContent">
                <button className="transparentButton" onClick={toggleCompany}>
                  {i18next.t('about.company.1')}
                </button><br/>
                {showCompany && (
                  <p id="pMission" className="text">
                    <br/><span>{i18next.t('about.company.2')}<br/></span><br/>
                  </p>
                )}
              </div>
            </div>
            <div id='boxInfos'>
              <div>
                <button className="arrowButton" onClick={toggleMission}>
                  <img src={arrow} alt="arrow" className={`arrow ${showMission ? 'rotate' : 'animate-close'}`} />
                </button>
              </div>
              <div className="boxContent">
                <button className="transparentButton" onClick={toggleMission}>
                  {i18next.t('about.mission.1')}
                </button><br/>
                {showMission && (
                  <p id="pCompany" className="text">
                    <br/><span>{i18next.t('about.mission.2')}<br/></span><br/>
                  </p>
                )}
              </div>
            </div>
            <div id='boxInfos'>
              <div>
                <button className="arrowButton" onClick={toggleValues}>
                  <img src={arrow} alt="arrow" className={`arrow ${showValues ? 'rotate' : 'animate-close'}`} />
                </button>
              </div>
              <div className="boxContent">
                <button className="transparentButton" onClick={toggleValues}>
                  {i18next.t('about.vision.1')}
                </button>
                {showValues && (
                  <div>
                    <p id="pValues" className="text">
                      <br/><span>{i18next.t('about.vision.2')}</span><br/><br/>
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div id='boxInfos'>
              <div>
                <button className="arrowButton" onClick={togglePurpose}>
                  <img src={arrow} alt="arrow" className={`arrow ${showPurpose ? 'rotate' : 'animate-close'}`} />
                </button>
              </div>
              <div className="boxContent">
                <button className="transparentButton" onClick={togglePurpose}>
                  {i18next.t('about.purpose.1')}
                </button>
                {showPurpose && (
                  <div>
                    <p id="pPurpose" className="text"><br/>
                      <span className='blue' id='itemPurpose'>{i18next.t('about.purpose.excellence.1')}</span>: {i18next.t('about.purpose.excellence.2')}<br/><br/>
                      <span className='blue' id='itemPurpose'>{i18next.t('about.purpose.innovation.1')}</span>: {i18next.t('about.purpose.innovation.2')}<br/><br/>
                      <span className='blue' id='itemPurpose'>{i18next.t('about.purpose.trust.1')}</span>: {i18next.t('about.purpose.trust.2')}<br/><br/>
                      <span className='blue' id='itemPurpose'>{i18next.t('about.purpose.connectivity.1')}</span>: {i18next.t('about.purpose.connectivity.2')}<br/><br/>
                      <span className='blue' id='itemPurpose'>{i18next.t('about.purpose.agility.1')}</span>: {i18next.t('about.purpose.agility.2')}<br/><br/>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div id="boxTextAbout">
            <p id="pAbout" className="text">
                <span className='blue'>{i18next.t('about.joinUs.1')}</span>{i18next.t('about.joinUs.2')}<span className='blue'>{i18next.t('about.joinUs.3')}</span>{i18next.t('about.joinUs.4')}<span className='blue'>{i18next.t('about.joinUs.5')}</span><br/>
                <span className='blue'>{i18next.t('about.joinUs.6')}</span><br/>
            </p>
          </div>
          <div id="buttonBox">
          <button id='ctaButton'><a href="https://wa.me/5515998556000" target="_blank" rel="noopener noreferrer" >{i18next.t('about.contactUs')}</a></button>
          </div>  
        </div>
      </div>
    </div>
    </>
  )
}

export default About
