import './style.css'

import icon_neutra from "../../assets/images/icon-neutra.svg"
import icon_brazil from "../../assets/images/brasil.svg"
import icon_eua from "../../assets/images/eua.svg"
import icon_spanish from "../../assets/images/spanish.svg"

import { i18n } from "../../translate/i18n"
import { useState, useEffect } from 'react'


function Header(){

  const menu_information = [
    {
      id: 'home_link',
      text: i18n.t('header.menus.home'),
      href: 'homeBox'
    },
    {
      id: 'services_link',
      text: i18n.t('header.menus.services'),
      href: 'myBody'
    },
    {
      id: 'roof_link',
      text: i18n.t('header.menus.coverage'),
      href: 'coverageBox'
    },
    {
      id: 'about_link',
      text: i18n.t('header.menus.about'),
      href: 'textCoverage2'
    },
    {
      id: 'contact_link',
      text: i18n.t('header.menus.contact'),
      href: 'faqBox'
    },
  ]
  const [isActiveBurger, setIsActiveBurger] = useState(false) 

  const [isScrolled, setIsScrolled] = useState(false);

  const handleActiveBurger = () => {
    setIsActiveBurger(!isActiveBurger)
  }
 
  const handleScrollInBody = (state) => {
      /**
       * This Function added the no-scroll class
       * in the element html body when the state isActiveBurger
       * is equal "true" and remove when the state isActiveBurger
       * is equal "false", this is to resolve of the scroll
       * probleam in background element when the menu burger is 
       * opened
       */
      state? 
      document.body.classList.add('no-scroll')
      :
      document.body.classList.remove('no-scroll')
  }

  const handleResize = () => {
    if(window.innerWidth > 1250){
      setIsActiveBurger(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {

    handleScrollInBody(isActiveBurger)
  
  }, [isActiveBurger]);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setIsScrolled(isScrolled);
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
 }, []);


 const handleSelectChangeLang = event => {
  localStorage.setItem('i18nextLng', event.target.lang)
  window.location.reload()
 }



  return(
    <>
      <header className={`${isActiveBurger? 'isActiveHeader' : '' } header ${isScrolled ? 'header-dark' : ''}`}>
        <div className='boxLogoBurger'>
          <div className="headerLogo">
            <img src={icon_neutra} alt={i18n.t('header.logo.description_image')}/>
          </div>
          <div className={`burgerBoxOutside`}>
            <div className='burgerBoxInside' onClick={handleActiveBurger}>
              <div className='burgerLine1'/>
              <div className='burgerLine2'/>
              <div className='burgerLine3'/>
            </div>
          </div>
        </div>
        <nav className='navMenu'>
          <ul>
          {
            menu_information.map(item_menu => {
              return (
                <li 
                  key={item_menu.id} 
                  id={item_menu.id}
                  onClick={() => {
                    // Scroll to the element with the ID matching item_menu.id
                    const element = document.getElementById(item_menu.href);
                    if (element) {
                      setIsActiveBurger(false) 
                      setIsScrolled(false)                    
                      element.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                >
                  {item_menu.text}
                </li>
               );
             })
            }
          </ul>
          <div className="languages">
            <img lang='pt-BR' src={icon_brazil} alt={i18n.t('header.description_pt_image')} onClick={handleSelectChangeLang}/>
            <img lang='es-ES'src={icon_spanish} alt={i18n.t('header.description_spa_image')}  onClick={handleSelectChangeLang}/>
            <img lang='en-US' src={icon_eua} alt={i18n.t('header.description_en_image')} onClick={handleSelectChangeLang}/>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Header